import * as React from "react"
import VideoGameIcon from '../images/videogame.svg'
import BookIcon from '../images/book.svg'
import TvIcon from '../images/tv.svg'
import MovieIcon from '../images/movie.svg'
import BoardGameIcon from '../images/boardgame.svg'
import FunIcon from '../images/fun.svg'
import CookingIcon from '../images/cooking.svg'
import ShoppingIcon from '../images/shopping.svg'
import YouTubeIcon from '../images/youtube.svg'
import PodcastIcon from '../images/podcast.svg'

const Tag = (props) => {
  let img;
  const alt = `${props.name}-tag`;
  const style = 'content-tag';
  switch(props.name) {
    case 'board-games':
      img = <img className={style} src={BoardGameIcon} alt={alt}/>
      break;
    case 'video-games':
      img = <img className={style} src={VideoGameIcon} alt={alt}/>
      break;
    case 'movies':
      img = <img className={style} src={MovieIcon} alt={alt}/>
      break;
    case 'books':
      img = <img className={style} src={BookIcon} alt={alt}/>
      break;
    case 'tv':
      img = <img className={style} src={TvIcon} alt={alt}/>
      break;
    case 'shopping':
      img = <img className={style} src={ShoppingIcon} alt={alt}/>
      break;
    case 'cooking':
      img = <img className={style} src={CookingIcon} alt={alt}/>
      break;
    case 'youtube':
      img = <img className={style} src={YouTubeIcon} alt={alt}/>
      break;
    case 'podcast':
      img = <img className={style} src={PodcastIcon} alt={alt}/>
      break;
    default :
      img = <img class={style} src={FunIcon} alt={alt}/>
  }

  return (
    <div className="tag-container">
      {img}
    </div>
  )
}

export default Tag
